<template>
  <div class="m-2">
    <b-row>
      <!-- First Stamenet -->
      <b-col cols="8">
        <b-card class="h-100 m-0" style="padding: 20.25px">
          <b-card-title>
            <h4
              class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1"
            >
              Relevant Information
            </h4>
          </b-card-title>
          <b-row>
            <!-- Legal Status -->
            <b-col cols="4">
              <b-form-group label="Legal Status">
                <div
                  style="min-height: 3rem !important"
                  class="form-group-input2 d-flex align-items-center"
                  :class="statusDark ? 'dashboard' : 'dashboard2'"
                >
                  <span>{{ arrayStatusLead[client.status_l - 1] }}</span>
                </div>
              </b-form-group>
            </b-col>
            <!-- Contract -->
            <b-col cols="4">
              <b-form-group label="Language">
                <div
                  style="min-height: 3rem !important"
                  class="form-group-input2 d-flex align-items-center"
                  :class="statusDark ? 'dashboard' : 'dashboard2'"
                >
                  <span v-if="client.language == 'EN'">English</span>
                  <span v-if="client.language == 'ES'">Spanish</span>
                </div>
              </b-form-group>
            </b-col>
            <!-- Mobile  -->
            <b-col cols="4">
              <b-form-group label="Mobile">
                <template #label>
                  <span
                    class="d-flex justify-content-between align-items-center"
                  >
                    Mobile
                    <span
                      class="see-tracking"
                      @click="openModalTrackingMobile(3, 'MOBILE')"
                      >See Tracking</span
                    >
                  </span>
                </template>
                <div
                  style="min-height: 3rem !important"
                  class="form-group-input2 d-flex justify-content-between align-items-center"
                  :class="statusDark ? 'dashboard' : 'dashboard2'"
                >
                  <span>{{ client.mobile }}</span>
                  <!-- <feather-icon
                      icon="MoreVerticalIcon"
                      class="cursor-pointer"
                      size="15"
                      @click="openModalTrackingMobile(3, 'MOBILE')"
                    /> -->
                </div>
              </b-form-group>
            </b-col>
            <!-- Email -->
            <b-col cols="4">
              <b-form-group label="Email">
                <div
                  style="min-height: 3rem !important"
                  class="form-group-input2 d-flex align-items-center"
                  :class="statusDark ? 'dashboard' : 'dashboard2'"
                >
                  <span>{{ client.email }}</span>
                </div>
              </b-form-group>
            </b-col>
            <!-- D.O.B -->
            <b-col cols="4">
              <b-form-group label="D.O.B">
                <div
                  style="min-height: 3rem !important"
                  class="form-group-input2 d-flex align-items-center"
                  :class="statusDark ? 'dashboard' : 'dashboard2'"
                >
                  <span>{{ client.dob | myGlobal }}</span>
                </div>
              </b-form-group>
            </b-col>
            <!-- Monthly Pay -->
            <b-col cols="4">
              <b-form-group label="Monthly Pay">
                <template #label>
                  <span
                    class="d-flex justify-content-between align-items-center"
                  >
                    Monthly Pay
                    <span class="see-tracking" @click="openModalHistoryPayment"
                      >See Tracking</span
                    >
                  </span>
                </template>
                <b-skeleton v-if="validateMontlyPayOn" type="input" />
                <div
                  v-if="!validateMontlyPayOn"
                  style="min-height: 3rem !important"
                  class="form-group-input2 d-flex justify-content-between align-items-center w-100"
                  :class="statusDark ? 'dashboard' : 'dashboard2'"
                >
                  <div class="d-flex">
                    <span v-if="client.monthly_amount" class="mr-1"
                      >$ {{ client.monthly_amount }}</span
                    >
                    <status-monthly-pay class="mr-1" />
                    <span
                      :class="client.ispayment === 0 ? 'text-danger' : ''"
                      :style="client.ispayment === 2 ? 'color: #FFA500;' : ''"
                      >{{
                        client.ispayment === 0
                          ? "DEBTOR"
                          : client.ispayment === 2
                          ? "PENDING"
                          : ""
                      }}</span
                    >
                  </div>
                  <div class="d-flex">
                    <feather-icon
                      icon="Edit2Icon"
                      class="ml-1"
                      :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                      size="15"
                      @click="
                        isResponsibleAccount ? '' : openModalMonthlyPayment()
                      "
                    />
                    <!-- <feather-icon
                        icon="MoreVerticalIcon"
                        class="cursor-pointer ml-1"
                        size="15"
                        @click="openModalHistoryPayment"
                      /> -->
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <!-- SNN -->
            <b-col cols="4">
              <b-form-group label="SSN">
                <div
                  style="min-height: 3rem !important"
                  class="form-group-input2 d-flex align-items-center"
                  :class="statusDark ? 'dashboard' : 'dashboard2'"
                >
                  <span>{{ encryptedSSN }}</span>
                </div>
              </b-form-group>
            </b-col>
            <!-- <b-form-group label="Charge">
                <b-skeleton v-if="validateChargeOn" type="input" />
                <div
                  v-else
                  style="min-height: 3rem !important"
                  class="form-group-input2 d-flex justify-content-between dashboard"
                  :class="statusDark ? 'dashboard' : 'dashboard2'"
                >
                  <div>
                    <tabler-icon
                      v-if="client.charges == 0"
                      icon="CircleCheckIcon"
                      class="text-success"
                      :class="
                        currentUser.role_id == 2 || currentUser.role_id == 14
                          ? 'cursor-pointer'
                          : ''
                      "
                      size="22"
                      title="Approve Charge"
                      @click="openApproveCharge(client.charges)"
                    />
                    <b-img
                      v-else
                      :class="
                        currentUser.role_id == 2 || currentUser.role_id == 14
                          ? 'cursor-pointer'
                          : ''
                      "
                      title="Approve Charge"
                      :src="assetsImg + '/images/icons/dcharge.ico'"
                      @click="openApproveCharge(client.charges)"
                    />
                  </div>
                  <div>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      class="cursor-pointer"
                      size="15"
                      @click="openModalTrackingMobile(7, 'Charge')"
                    />
                  </div>
                </div>
              </b-form-group> -->
            <!-- Address-->
            <b-col cols="8">
              <b-form-group label="Address">
                <template #label>
                  <span
                    class="d-flex justify-content-between align-items-center"
                  >
                    Address
                    <span
                      class="see-tracking"
                      @click="openModalTrackingMobile(1, 'ADDRESS')"
                      >See Tracking</span
                    >
                  </span>
                </template>
                <div
                  style="min-height: 3rem !important"
                  class="form-group-input2 d-flex justify-content-between align-items-center"
                  :class="statusDark ? 'dashboard' : 'dashboard2'"
                >
                  <span id="date" name="start">
                    {{ client.street }}
                    {{ client.city }}
                    <span>,</span>
                    {{ client.states_eeuu_slug }}
                    <span>,</span>
                    {{ client.zipcode }}
                  </span>
                  <!-- <feather-icon
                      icon="MoreVerticalIcon"
                      class="cursor-pointer"
                      size="15"
                      @click="openModalTrackingMobile(1, 'ADDRESS')"
                    /> -->
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group label="Contact Schedule">
                <template #label>
                  <div class="d-flex justify-content-between">
                    <span>
                      Contact Time:
                      <tabler-icon
                        :id="'hours-target'"
                        class="text-success cursor-pointer"
                        size="15"
                        icon="QuestionMarkIcon"
                      />
                    </span>
                    <span
                      class="see-tracking"
                      @click="openModalScheduleTracking"
                      >See Tracking</span
                    >
                    <b-tooltip
                      v-if="hoursToSee"
                      custom-class="ga"
                      :target="'hours-target'"
                      triggers="hover"
                      placement="top"
                    >
                      <div>
                        <h5 class="font-weight-bolder">Office Hours</h5>

                        <span v-for="(item, i) in hoursToSee" :key="i">
                          <span
                            class="font-weight-bolder text-justify"
                            style="max-width: 1px !important"
                            >{{ item.day }}
                          </span>
                          <span
                            class="text-justify"
                            style="max-width: 10px !important"
                          >
                            {{
                              ": From: " +
                              (item.from ? item.from : "00:00") +
                              " To: " +
                              (item.to ? item.to : "23:59")
                            }}</span
                          >
                          <br />
                        </span>
                      </div>
                    </b-tooltip>
                  </div>
                </template>
                <div
                  style="min-height: 3rem !important"
                  :style="{
                    color: statusDark
                      ? '#D8D8D8 !important'
                      : '#6e6b7b !important',
                  }"
                  class="d-flex justify-content-between dashboard rounded align-items-center px-1"
                  :class="
                    statusDark
                      ? 'dashboard kendo-clock3232'
                      : 'dashboard2 kendo-clock3233'
                  "
                >
                  <kendo-timepicker
                    v-model="client.current_schedule"
                    :format="'HH:mm'"
                    minutes-step="30"
                    class="w-100 rounded bg-transparent"
                    :class="
                      statusDark
                        ? 'kendo-time-color3232'
                        : 'kendo-time-color3233'
                    "
                    :style="{
                      color: statusDark
                        ? '#D8D8D8 !important'
                        : '#6e6b7b !important',
                    }"
                    placeholder="Select hour"
                    @change="setNewHourToContact"
                  />
                  <div class="d-flex">
                    <!-- <feather-icon
                        @click="openModalScheduleTracking"
                        icon="MoreVerticalIcon"
                        z
                        class="cursor-pointer ml-1"
                        size="15"
                      /> -->
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              v-if="[1, 2, 14, 17].some((rol) => rol == currentUser.role_id)"
            >
              <b-form-group>
                <template #label>
                  <span style="visibility: hidden; padding-bottom: 0"> - </span>
                </template>
                <b-button
                  class="w-100 btn-ce"
                  :disabled="isResponsibleAccount"
                  @click="openCustomerTracking()"
                >
                  <span class="text-nowrap">+ Add Customer Tracking</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- Second Statement -->
      <b-col cols="4">
        <b-card class="h-100 m-0" style="padding: 20.25px">
          <b-card-title>
            <h4
              class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1"
            >
              Memo
            </h4>
          </b-card-title>
          <b-form-group class="m-0">
            <b-row class="mx-0 mt-2">
              <b-textarea
                v-model="client.memo"
                :disabled="!campeditmemo"
                class="memo-class form-group"
                no-resize
              />
            </b-row>
            <b-row class="mx-0 mt-2">
              <b-col cols="6">
                <div v-if="campeditmemo" class="d-flex">
                  <b-button
                    class="btn-circle btn-memo"
                    @click="saveeditmemo(client.id)"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="text-white"
                      size="20"
                    />
                  </b-button>
                  <b-button
                    class="btn-circle ml-1"
                    variant="danger"
                    @click="canceleditmemo"
                  >
                    <feather-icon icon="XIcon" class="text-white" size="20" />
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    class="btn-circle btn-memo"
                    @click="isResponsibleAccount ? '' : editmemo()"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class="text-white"
                      size="20"
                    />
                  </b-button>
                </div>
              </b-col>
              <b-col cols="6">
                <!-- v-if="campuserchangememo" -->
                <div
                  class="col-lg-12"
                  style="padding: 0 10px; text-align: right"
                >
                  <span style="color: #cccccc">
                    Updated by:
                    {{ client.user_memo == null ? "-" : client.user_memo }} /
                    <span v-if="client.date_memo == null">-</span>
                    <span v-else>{{ client.date_memo | myGlobalDay }}</span>
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <!-- <div style="height: 100%; margin-top: 2.2rem">
              <feather-icon
                icon="CheckIcon"
                class="cursor-pointer ml-1 text-success"
                size="20"
                @click="saveeditmemo(client.id)"
              />
              <feather-icon
                icon="TrashIcon"
                class="cursor-pointer ml-1 text-danger mt-1"
                size="20"
                @click="canceleditmemo"
              />
            </div> -->
          <!--        <task-notes />-->
          <!-- <b-row class="mt-1">
          <b-col lg="12">
            <b-form-group
              label="SCORES"
              :label-style="isDarkSkin ? 'text-white !important' : 'text-black'"
              :label-class="
                isDarkSkin
                  ? 'k-font-weight-bold font-medium-1'
                  : 'k-font-weight-bold font-medium-1'
              "
            >
              <template v-slot:label>
                <div class="d-flex justify-content-between align-items-center">
                  <span>SCORES</span>
                  <div v-if="currentUser.role_id == 1">
                    <b-button
                      variant="secondary"
                      size="sm"
                      class="cursor-pointer mr-1"
                      @click="openmodalscore(null)"
                      >Add</b-button
                    >
                    <span>|</span>
                    <b-button
                      variant="secondary"
                      size="sm"
                      class="cursor-pointer ml-1"
                      @click="openmodalscoresall"
                      >Edit</b-button
                    >
                  </div>
                </div>
              </template>

              <b-table
                ref="refScoresList"
                :items="allscores"
                :fields="fields"
                primary-key="id"
                table-class="text-nowrap"
                responsive="sm"
                show-empty
                style="margin-bottom: 0px"
                sticky-header="21vh"
                :busy.sync="isBusy"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>

                <template #head(date)>
                  <span>Date</span>
                </template>

                <template #head(file)>
                  <span>File</span>
                </template>
                <template #head(equifax)>
                  <span
                    style="
                      color: #981e32;
                      font-weight: bold;
                      text-align: center;
                      font-style: italic;
                    "
                    >EQUIFAX</span
                  >
                </template>

                <template #head(experian)>
                  <span
                    style="
                      color: #0566b7;
                      font-weight: bold;
                      text-align: center;
                    "
                    >Experian</span
                  >
                </template>
                <template #head(trans_union)>
                  <template style="font-weight: bold; text-align: center">
                    <span>Trans</span>
                    <span style="color: #4d917a"> Union</span>
                  </template>
                </template>
                <template #cell(date)="data">
                  <div style="width: 60px">
                    {{ data.item.date | myGlobal }}
                  </div>
                </template>
                <template #cell(file)="data">
                  <div style="width: 10px">
                    <a
                      v-if="data.item.route_pdf != null"
                      :href="data.item.route_pdf"
                      target="_blanck"
                    >
                      <amg-icon
                        size="18"
                        class="text-danger"
                        icon="FilePdfIcon"
                      />
                    </a>

                    <span v-else>-- </span>
                  </div>
                </template>

                <template #cell(cr)="data">
                  <div style="width: 10px">
                    <router-link
                      v-if="data.item.state == 1"
                      target="_blank"
                      :to="{
                        name: 'ce-customer-service-report-lead',
                        params: {
                          idfile: data.item.score_id,
                          idlead: data.item.lead_id,
                          accountId: client.id,
                          modul: 25,
                        },
                      }"
                    >
                      <feather-icon icon="FileTextIcon" size="15" />
                    </router-link>
                  </div>
                </template>
                <template #cell(equifax)="data">
                  <div>
                    <span
                      style="
                        width: 20px;
                        color: #981e32;
                        font-weight: bold;
                        font-size: 15px;
                        border-top: none;
                        text-align: center;
                      "
                      >{{ data.item.equifax }}
                    </span>
                  </div>
                </template>

                <template #cell(experian)="data">
                  <template>
                    <span
                      style="
                        color: #0566b7;
                        font-weight: bold;
                        font-size: 15px;
                        border-top: none;
                        text-align: center;
                      "
                      >{{ data.item.experian }}
                    </span>
                  </template>
                </template>

                <template #cell(trans_union)="data">
                  <template>
                    <span
                      style="
                        color: #4d917a;
                        font-weight: bold;
                        font-size: 15px;
                        border-top: none;
                        text-align: center;
                      "
                      >{{ data.item.transunion }}
                    </span>
                  </template>
                </template>
                <template #cell(deleted_ac)="data">
                  {{ `${data.item.deleted_ac} / ${data.item.updated_ac}` }}
                </template>
              </b-table>
            </b-form-group></b-col
          >
        </b-row> -->
        </b-card>
      </b-col>
    </b-row>
    <b-row class="p-1">
      <lead-update
        v-if="!isResponsibleAccount"
        ref="leadUpdate"
        :isReady="isReady"
        :lead="S_LEAD_EDIT"
        :type-edit="typeEdit"
        @refresh="refreshUpdateLeads"
      />
    </b-row>
    <add-customer-tracking
      v-if="modalCustomerTracking"
      @close="closeCustomerTracking"
      :idClient="client.id"
    />

    <modal-history
      v-if="modalHistory"
      :id="idClient"
      :modal-history="modalHistory"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <modal-co-applicant
      v-if="modalApplicant"
      :valo-co="valoCo"
      @refresh="refresh"
      @close="closeModalApplicant"
    />
    <modal-tracking-leads
      v-if="modalTrackingMobile"
      :modal-tracking-mobile="modalTrackingMobile"
      :type="typefields"
      :namefiels="namefiels"
      @closeModalTracking="closeModalTracking"
    />
    <modal-change-advisor
      v-if="modalChangeAdvisor"
      @refresh="refresh"
      @close="closeModalChangeAdvisor"
    />
    <modal-history-advisor
      v-if="modalHistoryAdvisor"
      @closeModalHistoryAdvisor="closeModalHistoryAdvisor"
    />
    <modal-monthly-edit
      v-if="modalMonthlyPayment"
      :modal-monthly-payment="modalMonthlyPayment"
      @updateClient="refresh"
      @close="closeModalMonthlyPayment"
    />
    <modal-history-monthly-payment
      v-if="modalHistoryPayment"
      @close="closeModalHistoryPayment"
    />

    <modal-tracking-co-applicant
      v-if="modalTrackingCoApplicantOn"
      @close="closeModalTrackingCoApplicant"
    />

    <contract-fee-modal
      v-if="modalContractOn"
      :modal="modal"
      :contract-fee="contractFee"
      @close="closeContractFeeModal"
      @reload="refresh"
    />

    <modal-tracking-history-claims
      v-if="modalHistoryClaims"
      @close="closeModalTrackingHistoryClaims"
    />

    <modal-new-claim
      v-if="modalCreateNewClaim"
      ref="newClaim"
      :is-from-account="true"
      :account-id="$route.params.idClient"
      :is-new-claim="true"
      @refresh="refresh"
      @onClose="closeCreateNewClaim"
    />
    <modal-add-score
      v-if="modalAddScore"
      @refresh="scores"
      @close="closeModalAddScore"
    />
    <modal-scores
      v-if="modalScores"
      @allScores="scores"
      @close="closeModalScore"
    />
    <modal-schedule-tracking
      v-if="isModalScheduleTrackingOpened"
      :clientSaleId="client.sale_id"
      @click="openModalScheduleTracking"
      @close="closeModalScheduleTracking"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapState, mapActions } from "vuex";
// Import Services
import ModalTrackingCoApplicant from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/modal-history-co-applicant/ModalTrackingCoApplicant.vue";
import ContractFeeModal from "@/views/crm/views/sales-made/components/modals/ContractFeeModal.vue";
import ModalTrackingHistoryClaims from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalTrackingHistoryClaims.vue";
import ModalNewClaim from "@/views/debt-solution/views/claims/components/ModalNewClaim.vue";
import ModalAddScore from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalAddScore.vue";
import ModalScores from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalScores.vue";
import TaskNotes from "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
// Import Component
import ModalCoApplicant from "@/views/commons/components/clients/dashboard/information-client/modals/ModalCoApplicant.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory.vue";
import ModalTrackingLeads from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalTrackingLeads.vue";
import ModalChangeAdvisor from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalChangeAdvisor.vue";
import ModalHistoryAdvisor from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryAdvisor.vue";
import StatusMonthlyPay from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/StatusMonthlyPay.vue";
import ModalMonthlyEdit from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalMonthlyEdit.vue";
import ModalHistoryMonthlyPayment from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryMonthlyPayment.vue";
import ClientsBoostDashboardServices from "@/views/boost-credit/views/clients/dashboard/services/clients.boost.dashboard.services";
import OfficeHoursService from "@/views/ce-digital/sub-modules/settings/views/office-hours/service/office-hours.service";
import ModalScheduleTracking from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/modals/ModalScheduleTracking.vue";
import AddCustomerTracking from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/modals/AddCustomerTracking.vue";
import moment from "moment";
import LeadUpdate from "@/views/credit-experts/views/clients/dashboard/information-client/LeadUpdate.vue";

export default {
  components: {
    vSelect,
    // Modals
    ModalHistory,
    ModalCoApplicant,
    ModalTrackingLeads,
    ModalChangeAdvisor,
    ModalHistoryAdvisor,
    StatusMonthlyPay,
    ModalMonthlyEdit,
    ModalHistoryMonthlyPayment,
    ModalTrackingCoApplicant,
    ContractFeeModal,
    ModalTrackingHistoryClaims,
    ModalNewClaim,
    ModalAddScore,
    ModalScores,
    ModalScheduleTracking,
    AddCustomerTracking,
    LeadUpdate,
  },
  data() {
    return {
      isReady: true,
      typeEdit: "lead",
      disableGoals: true,
      typegoal: null,
      typegoals: [],
      fields: [
        {
          key: "date",
          label: "Date",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "file",
          label: "File",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "equifax",
          label: "Equifax",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "experian",
          label: "Experian",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "trans_union",
          label: "Trans Union",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "negative_ac",
          label: "Negative",
          tdClass: "text-center text-danger font-weight-bolder",
          thClass: "text-center",
        },
        {
          key: "in_dispute_ac",
          label: "In Dispute",
          tdClass: "text-center text-warning font-weight-bolder",
          thClass: "text-center",
        },
        {
          key: "deleted_ac",
          label: "Deleted / Updated",
          tdClass: "text-center text-success font-weight-bolder",
          thClass: "text-center",
        },
      ],
      isBusy: false,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      hoursToSee: [],
      lastClaim: null,
      idClient: "",
      isModalScheduleTrackingOpened: false,
      created: "",
      balanceprops: "",
      advisor: null,
      status: null,
      date_start: "",
      status_welco: null,
      lead_status: null,
      cstreet: null,
      ccity: "",
      cstates_eeuu_slug: "",
      czipcode: null,
      selementCost: null,
      advisors: [],
      allscores: [],
      viewp: true,
      valoCo: null,
      typefields: "",
      namefiels: "",
      typeApprove: "",
      //   modals
      modalHistoryClaims: false,
      modalHistory: false,
      modalApplicant: false,
      modalTrackingMobile: false,
      modalChangeAdvisor: false,
      modalHistoryAdvisor: false,
      modalMonthlyPayment: false,
      modalHistoryPayment: false,
      modalTrackingCoApplicantOn: false,
      modalContractOn: false,
      modalCreateNewClaim: false,
      modalAddScore: false,
      modalScores: false,
      newHourToContact: "",
      validateCoAppOn: false,
      validateAdvisorOn: false,
      validateMontlyPayOn: false,
      validateChargeOn: false,
      validateNewClaim: false,
      arrayStatusLead: [
        "U.S Citizen",
        "Residente Alien",
        "Non-Resident Alien",
        "Student Green Card",
        "Work Green Card",
      ],
      modal: {},
      contractFee: {
        programName: "",
        clientName: "",
        saleId: "",
        id: "",
        initialPaymentStatus: null,
        editModal: false,
        statusSale: null,
      },
      campeditmemo: false,
      campuserchangememo: true,
      temporalMemo: "",
      notesWelcomeCustomerServiceModalController: false,
      scheduleForToday: {
        id: null,
        id_update: null,
        day: null,
        from: "00:00",
        to: "23:59",
      },
      validateHours: null,
      modalCustomerTracking: false,
      date_tracking: "",
      formatDate: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      minDate: new Date().toISOString().substr(0, 10),
    };
  },
  async created() {
    this.officeHoursPerDay();
  },
  computed: {
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      clientResponse: "DebtSolutionClients/G_CLIENTS_RESPONSE",
    }),
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    statusRedirect() {
      let router;
      switch (this.moduleId) {
        case 2:
          router = "/crm/clients/edit/";
          break;
        case 6:
          router = "/creditexperts/clients/edit/";
          break;
        case 3:
          router = "/bussiness/clients/edit/";
          break;
        case 5:
          router = "/debtsolution/clients/edit/";
          break;
        case 7:
          router = "/boostcredit/clients/edit/";

          break;
        case 8:
          router = "/taxresearch/clients/edit/";
          break;
        case 10:
          router = "/claimdepartment/clients/edit/";
          break;
        default:
          router = "";
      }
      return router;
    },
    encryptedSSN() {
      return this.encryptDocument(this.client.ssn);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },

  watch: {
    async client(newVal) {
      if (newVal) {
        await this.scores();
      }
    },
  },
  async mounted() {
    await this.listTypeGoal();
    this.typegoal = this.client.goals;
    await this.getLastClaim();
    await this.scores();
  },

  methods: {
    ...mapActions({
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_CLIENTS: "DebtSolutionClients/A_GET_CLIENTS",
    }),
    closeCustomerTracking() {
      this.modalCustomerTracking = false;
    },
    openCustomerTracking() {
      this.modalCustomerTracking = true;
    },
    openNotesWelcomeCSD() {
      this.notesWelcomeCustomerServiceModalController = true;
    },
    encryptDocument(document) {
      return document ? `xxx-xx-${document.substr(7)}` : "";
    },
    closeNotesWelcomeCSD() {
      this.notesWelcomeCustomerServiceModalController = false;
    },
    cancelGoals() {
      this.disableGoals = true;
    },
    changeGoals() {
      this.disableGoals = false;
    },
    openmodalscore() {
      this.modalAddScore = true;
    },
    closeModalAddScore() {
      this.modalAddScore = false;
    },

    async openmodalscoresall() {
      await this.scores();
      this.modalScores = true;
    },
    async closeModalScore() {
      await this.scores();
      this.modalScores = false;
    },
    async saveeditmemo() {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id: this.$route.params.idClient,
            user_id: this.currentUser.user_id,
            memo: this.client.memo,
          };
          const data = await ClientDashboardService.editMemo(params);
          if (data.status === 200) {
            this.campeditmemo = false;
            this.campuserchangememo = true;
            this.client.user_memo = this.currentUser.fullName;
            this.client.date_memo = moment().format("YYYY-MM-DD HH:mm:ss");
            await this.refresh();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    editmemo() {
      this.temporalMemo = this.client.memo;
      this.campeditmemo = true;
      this.campuserchangememo = false;
    },
    canceleditmemo() {
      this.client.memo = this.temporalMemo;

      this.campeditmemo = false;
      this.campuserchangememo = true;
    },

    openCreateNewClaim() {
      this.modalCreateNewClaim = true;
    },
    closeCreateNewClaim() {
      this.modalCreateNewClaim = false;
    },
    openModalTrackingHistoryClaims() {
      this.modalHistoryClaims = true;
    },
    closeModalTrackingHistoryClaims() {
      this.modalHistoryClaims = false;
    },

    openContractFeeModal() {
      this.modal.contract_fee = true;
      (this.contractFee.programName = this.client.program_name),
        (this.contractFee.clientName = this.client.client_name),
        (this.contractFee.saleId = this.client.sale_id),
        (this.contractFee.id = this.client.lead_id),
        (this.contractFee.initialAmount = 2),
        (this.contractFee.editModal = false),
        (this.contractFee.statusSale = 4),
        (this.modalContractOn = true);
    },
    closeContractFeeModal() {
      this.modalContractOn = false;
    },
    async refresh() {
      if (this.modalApplicant === true) {
        this.validateCoAppOn = true;
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateCoAppOn = false;
        }
      }
      if (this.modalChangeAdvisor == true) {
        this.validateAdvisorOn = true;
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateAdvisorOn = false;
        }
      }
      if (this.modalMonthlyPayment === true) {
        this.validateMontlyPayOn = true;
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateMontlyPayOn = false;
        }
      }
      if (this.modalCreateNewClaim === true) {
        this.validateNewClaim = true;
        const data = await this.getLastClaim();
        if (data) {
          this.validateNewClaim = false;
        }
      }

      if (this.validateChargeOn === true) {
        const data = await this.$store.dispatch(
          "DebtSolutionClients/A_GET_CLIENTS",
          {
            id: this.$route.params.idClient,
          }
        );
        if (data.status === 200) {
          this.validateChargeOn = false;
        }
      }
    },

    openModalTrackingCoApplicant() {
      this.modalTrackingCoApplicantOn = true;
    },

    closeModalTrackingCoApplicant() {
      this.modalTrackingCoApplicantOn = false;
    },

    //   OpenModals
    openModalHistory() {
      this.modalHistory = true;
    },
    closeModalHistory() {
      this.modalHistory = false;
    },
    coApplicant(valoCo) {
      this.valoCo = valoCo;
      this.modalApplicant = true;
    },
    closeModalApplicant() {
      this.modalApplicant = false;
    },
    openModalTrackingMobile(type, name) {
      this.typefields = type;
      this.namefiels = name;
      this.modalTrackingMobile = true;
    },
    closeModalTracking() {
      this.modalTrackingMobile = false;
    },
    openModalChangeAdvisor() {
      this.modalChangeAdvisor = true;
    },
    closeModalChangeAdvisor() {
      this.modalChangeAdvisor = false;
    },
    openModalHistoryAdvisor() {
      this.modalHistoryAdvisor = true;
    },
    closeModalHistoryAdvisor() {
      this.modalHistoryAdvisor = false;
    },
    openModalMonthlyPayment() {
      this.modalMonthlyPayment = true;
    },
    closeModalMonthlyPayment() {
      this.modalMonthlyPayment = false;
    },
    openModalHistoryPayment() {
      this.modalHistoryPayment = true;
    },
    closeModalHistoryPayment() {
      this.modalHistoryPayment = false;
    },
    // openApproveCharge(id_type) {
    //   if (this.currentUser.role_id != 2 && this.currentUser.role_id != 14)
    //     return;
    //   if (id_type === 0) {
    //     if (this.currentUser.role_id === 1 || this.currentUser.role_id === 2) {
    //       this.chargesdisabled();
    //     } else {
    //       this.typeApprove = 1;
    //       this.ModalApproveSupervisor = true;
    //     }
    //   } else {
    //     this.chargesdisabled();
    //   }
    // },
    // async chargesdisabled() {
    //   const confirm = await this.showConfirmSwal();
    //   if (confirm.isConfirmed) {
    //     this.validateChargeOn = true;
    //     try {
    //       const params = {
    //         id: this.$route.params.idClient,
    //         iduser: this.currentUser.user_id,
    //       };
    //       const data = await ClientDashboardService.updateDrb(params);
    //       if (data.status === 200) {
    //         await this.refresh();
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // },
    async searchAccount() {
      try {
        this.addPreloader();
        const response = await ClientDashboardService.search({
          id: this.idClient,
        });
        this.created = this.client.creates;
        this.balanceprops = this.client.balance;
        this.advisor = this.client.advisor_id;
        this.status = this.client.status;
        this.date_start = this.client.start_date;
        this.status_welco = this.client.s_welcome;
        this.lead_status = this.client.status_l;
        this.cstreet = this.client.street;
        this.ccity = this.client.city;
        this.cstates_eeuu_slug = this.client.state_name;
        this.czipcode = this.client.zipcode;
        this.selementCost = this.client.selement_cost;
        this.typegoal =
          this.client.goals == null ? [] : JSON.parse(this.client.goals);
        if ((this.client.coappname = "")) {
          this.client.coappname = false;
        }
        let module = 0;
        switch (parseInt(this.status)) {
          case 1:
            this.status_name = "Active";
            break;
          case 2:
            this.status_name = "Hold";
            break;
          case 3:
            this.status_name = "Transition";
            break;
          case 4:
            this.status_name = "Canceled";
            break;
          case 5:
            this.status_name = "Loyal";
            break;
          case 6:
            this.status_name = "Closed";
            break;
        }

        switch (this.client.program_id) {
          case 1:
            module = 3;
            break;
          case 2:
            module = 7;
            break;
          case 3:
            module = 6;
            break;
          case 4:
            module = 5;
            break;
          case 5:
            module = 8;
            break;
          case 6:
            module = 10;
            break;
        }

        const data = await ClientDashboardService.userModule(module, {
          roles: "[2,3]",
          type: "1",
        });
        this.advisors = data.data;
        this.removePreloader();
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with Account!"
        );
        this.removePreloader();
      }
    },
    async scores() {
      try {
        const data = await ClientDashboardService.allScore({
          id: this.client.lead_id,
        });
        if (data.status === 200) {
          this.allscores = data.data;
        }

        return this.allscores;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with score!"
        );
      }
    },
    async updateGoals() {
      try {
        this.spinnerOn = true;
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const params = {
            idaccount: this.$route.params.idClient,
            goals: this.typegoal,
          };

          const data = await ClientsBoostDashboardServices.updateGoals(params);

          if (data.status === 200) {
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async listTypeGoal() {
      try {
        const data = await ClientsBoostDashboardServices.getAllTypeGoal();
        if (data.status === 200) {
          this.typegoals = data.data;
        }

        return this.typegoals;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with score!"
        );
      }
    },

    async getLastClaim() {
      try {
        const params = {
          account_id: this.$route.params.idClient,
        };
        const data = await ClientDashboardService.getLastClaim(params);
        this.lastClaim = data.data[0];
        return this.lastClaim;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getStatusWelOnChange() {
      await this.$store.dispatch("DebtSolutionClients/A_SET_STATUS_WEL", 1);
      this.status_wel = this.client.s_welcome;
    },
    async officeHoursPerDay() {
      try {
        const result = await OfficeHoursService.getOfficeHoursPerDay();
        if (result.status === 200) {
          this.isBusy = true;
          this.hoursToSee = result.data;
          setTimeout(() => {
            this.isBusy = false;
          }, 900);
        }
      } catch (error) {
        throw error;
      }
    },
    setScheduleForTodayFromOfficeHours() {
      const now = moment();
      const day = moment(now).format("dddd");
      switch (day) {
        case "Sunday":
          this.dayInModal = "Sunday";
          this.validateHours = 1;
          break;
        case "Monday":
          this.dayInModal = "Monday";
          this.validateHours = 2;
          break;
        case "Tuesday":
          this.dayInModal = "Tuesday";
          this.validateHours = 3;
          break;
        case "Wednesday":
          this.dayInModal = "Wednesday";
          this.validateHours = 4;
          break;
        case "Thursday":
          this.dayInModal = "Thursday";
          this.validateHours = 5;
          break;
        case "Friday":
          this.dayInModal = "Friday";
          this.validateHours = 6;
          break;
        case "Saturday":
          this.dayInModal = "Saturday";
          this.validateHours = 7;
          break;
      }
      this.scheduleForToday = this.hoursToSee.find(
        (day) => day.id === this.validateHours
      );
      const errorMessage = `${this.dayInModal} contact hour is in the range of ${this.scheduleForToday.from} to ${this.scheduleForToday.to}`;
      if (
        this.client.current_schedule >= this.scheduleForToday.from &&
        this.client.current_schedule <= this.scheduleForToday.to
      ) {
        this.newHourToContactIsValid = true;
        return true;
      } else {
        this.newHourToContactIsValid = false;
        this.showInfoSwalCaro(`${errorMessage}`, "Info");
        return false;
      }
    },
    setNewHourToContact(e) {
      if (this.isResponsibleAccount) return;
      const oldValue = this.client.current_schedule;
      const newValue = e.sender._oldText;
      let isScheduleValid;
      this.showConfirmSwal(
        "Are you sure?",
        `You want to set new schedule to ${newValue}`
      ).then((response) => {
        if (response.isConfirmed) {
          isScheduleValid = this.setScheduleForTodayFromOfficeHours();
          if (isScheduleValid) {
            this.addPreloader();
            const params = {
              new_schedule: this.client.current_schedule,
              client_sale_id: this.client.sale_id,
              user_id: this.currentUser.user_id,
            };
            ClientDashboardService.updateClientSchedule(params)
              .then((response) => {
                if (response.status == 200) {
                  this.showSuccessSwal();
                  this.removePreloader();
                }
              })
              .catch((err) => {
                this.removePreloader();
                this.showErrorSwal(err);
              });
          } else {
            this.client.current_schedule = oldValue;
          }
        } else {
          this.client.current_schedule = oldValue;
        }
      });
    },
    openModalScheduleTracking() {
      this.isModalScheduleTrackingOpened = true;
    },
    closeModalScheduleTracking() {
      this.isModalScheduleTrackingOpened = false;
    },
    async refreshUpdateLeads() {
      this.addPreloader();
      this.isReady = false;
      await this.getLeadEdit();
      await this.getClients();
      this.$refs.leadUpdate.leadUpdateInit();
      this.isReady = true;
      this.removePreloader();
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    async getClients() {
      try {
        await this.A_GET_CLIENTS({ id: this.$route.params.idClient });
      } catch (error) {
        console.log("Something went wrong getClients", error);
      }
    },
  },
};
</script>

<style scoped>
.dashboard {
  background: #494949;
  opacity: 0.8 !important;
  border: none;
}

.dashboard2 {
  background: #f3f2f7;
  opacity: 0.8 !important;
  border: none;
}
this.currentUser.user_id {
  background: #efefef;
  opacity: 1 !important;
}

.multiselect__tags {
  min-height: 30px;
}

.multiselect__tag {
  font-size: 12px;
}

.multiselect {
  min-height: 30px;
}

.multiselect__select {
  height: 30px;
}
.form-group-input {
  padding: 0.438rem 1rem;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;

  border-radius: 0.357rem;
  width: 100%;
}
.disable-class {
  pointer-events: none;

  /* for "disabled" effect */
  opacity: 0.5;
}

.kendo-time-color3232::placeholder {
  color: #d8d8d8 !important;
}

.kendo-time-color3233::placeholder {
  color: #6e6b7b !important;
}
</style>

<style scoped>
* {
  font-family: "Montserrat", sans-serif;
}
.kendo-clock3232 .k-select span {
  color: #d8d8d8 !important;
}

.kendo-clock3233 .k-select span {
  color: #6e6b7b !important;
}
.memo-class {
  height: 200px !important;
  width: 100%;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.438rem 1rem;
  overflow: auto;
  white-space: pre-wrap;
}
.btn-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-memo {
  background-color: #232a46 !important;
}
.btn-ce {
  background-color: #3f7afa !important;
}
.see-tracking {
  cursor: pointer;
  color: rgb(0, 183, 255);
  transition: text-decoration 0.1s, font-weight 0.1s;
}
.see-tracking:hover {
  text-decoration: underline;
  font-weight: bold;
}
.form-group {
  font-size: 16px;
}
h4 {
  font-size: 22px;
}
</style>
