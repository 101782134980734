<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      centered
      :title="`HISTORY CLAIMS OF ${client.client_name}`"
      size="xmd"
      modal-class="modal-primary "
      hide-footer
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModalHistory"
    >
      <b-container fluid>
        <b-row class="mt-1 px-">
          <!-- Client -->
          <b-col cols="6">
            <h5 class="text-left">Client</h5>
            <p
              class="
                rounded
                text-primary
                border-primary
                font-medium-1
                text-center
                py10
              "
            >
              {{ client.client_name }}
            </p>
          </b-col>
          <b-col offset="3" cols="3">
            <b-button
              v-if="!fromCeDigital && validateAdministration"
              size="md"
              class="float-right"
              variant="info"
              @click="openCreateNewClaim"
            >
              New Claim
            </b-button>
          </b-col>
        </b-row>
        <!-- Table -->
        <b-table
          ref="refClientsList"
          :items="searchHistoryCoApplicant"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(claim)="data">
            <div>
              {{ data.item.code }}
            </div>
          </template>
          <template #cell(type)="data">
            <div>
              {{ data.item.type }}
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              {{ data.item.status }}
            </div>
          </template>
          <template #cell(created_by)="data">
            <div>
              {{ data.item.created_by_first_name }}
              {{ data.item.created_by_last_name }}
              <br />
              {{ data.item.created_at | myGlobalDay }}
            </div>
          </template>
          <template #cell(administrator)="data">
            <div>
              {{ data.item.admin_first_name }} {{ data.item.admin_last_name }}
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>

    <modal-new-claim
      v-if="modalCreateNewClaim"
      ref="newClaim"
      :is-from-account="true"
      :account-id="$route.params.idClient"
      :is-new-claim="true"
      @refresh="refresh"
      @onClose="closeCreateNewClaim"
    />
  </div>
</template>

<script>
// Import Services
// eslint-disable-next-line import/no-unresolved
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";
import ModalNewClaim from "@/views/debt-solution/views/claims/components/ModalNewClaim.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    ModalNewClaim,
  },
  props: {
    fromCeDigital: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isBusy: false,
      ownControl: false,
      historys: [],
      fields: [
        { key: "claim", label: "Claim" },
        { key: "type", label: "Type" },
        { key: "status", label: "Status" },
        { key: "created_by", label: "Created By" },
        { key: "administrator", label: "Administrator" },
      ],
      modalCreateNewClaim: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      G_CLAIMS: 'DebtSolutionClients/G_CLAIMS',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    validateAdministration(){
      return this.moduleId!=4;
    }
  },
  created() {
    this.ownControl = true;
  },

  methods: {
    ...mapActions({
      A_CLAIMS: 'DebtSolutionClients/A_CLAIMS',
    }),
    closeModalHistory() {
      this.$emit("close");
    },
    openCreateNewClaim() {
      this.modalCreateNewClaim = true;
    },
    closeCreateNewClaim() {
      this.modalCreateNewClaim = false;
    },
    refresh() {
      this.$refs.refClientsList.refresh();
    },

    async searchHistoryCoApplicant() {
      try {
        this.isBusy = true;
        const data = await this.A_CLAIMS({
          client_account_id: this.$route.params.idClient,
        });
        this.isBusy = false;
        this.historys = data.data;
        return this.G_CLAIMS;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with History!"
        );
        console.log("something went wrong with historyStatus", error);
      }
    },
  },
};
</script>
